<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-bottom-transition"
        max-width="800px"
      >
        <v-card>
          <v-toolbar color="primary" dark>Actualizar {{ title }}</v-toolbar>
          <v-progress-linear
            v-if="loading"
            loading="loading"
            indeterminate
            color="teal"
          ></v-progress-linear>
          <div class="text-h2 pa-8">
            <v-alert dense v-if="showError" border="top" type="error">
              <span class="overline">
                {{ errorMessage }}
              </span>
            </v-alert>
            <v-form ref="form" v-model="valid" :lazy-validation="false">
              <v-row
                v-if="
                  field != 'provider_id' &&
                  field != 'account_id' &&
                  field != 'document_code'
                "
              >
                <v-col cols="12">
                  <v-text-field
                    autofocus
                    v-model="value"
                    :label="label + '*'"
                    :rules="textRules"
                    :type="type"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="field == 'document_code'">
                <v-col cols="12" md="2">
                  <v-checkbox v-model="checkbox" label="Cheque"></v-checkbox>
                </v-col>
                <v-row v-if="checkbox">
                  <v-col cols="12" md="5">
                    <v-text-field
                      label="Cheque"
                      v-model="value"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      label="Fecha de emisión cheque"
                      type="date"
                      v-model="checkDate"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-col v-else cols="12" md="10">
                  <v-text-field
                    label="No. Transacción"
                    v-model="value"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="field == 'provider_id'">
                <provider-field
                  autofocus
                  v-model="provider"
                  :required="true"
                  :available="true"
                ></provider-field>
              </v-row>
              <v-row v-if="field == 'account_id'">
                <account-field
                  autofocus
                  :companyId="business"
                  v-model="value"
                ></account-field>
              </v-row>
            </v-form>
          </div>
          <v-card-actions>
            <v-btn @click="close()">Cancelar</v-btn>
            <v-btn @click="update()" :disabled="!valid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import requests from "@/services/requests";
import rules from "@/utils/form.rules";

export default {
  components: {
    ProviderField: () => import("@/components/fields/ProviderField"),
    AccountField: () => import("@/components/fields/AccountField"),
  },
  props: {
    dialog: { type: Boolean, required: true },
    title: { type: String, required: true },
    label: { type: String, required: true },
    uri: { type: String, required: true },
    textRules: [rules.required, rules.minLength(3)],
    field: { type: String, required: true },
    type: { type: String, required: false },
    business: { type: Number, required: false },
  },
  data() {
    return {
      checkbox: false,
      provider: 0,
      checkDate: undefined,
      showError: false,
      errorMessage: [],
      valid: false,
      value: "",
      upd: {},
      loading: false,
    };
  },
  methods: {
    update() {
      this.loading = true;
      if (this.type == "date") {
        this.upd["is_confirmed"] = true;
      }
      if (this.field == "document_code") {
        this.upd["is_check"] = this.checkbox;
        if (this.checkbox) {
          this.upd["check_date"] = this.checkDate;
        }
      }
      if (this.field == "provider_id") {
        this.upd[this.field] = this.provider.id;
      } else {
        this.upd[this.field] = this.value;
      }
      requests.patch(this.uri, this.upd).then((response) => {
        if (response.status == 200) {
          this.$emit("saved");
          this.value = "";
          this.upd = {};
          this.checkbox = false;
          this.$emit("close");
        } else if (response.status == 400) {
          if (response.data.detail != undefined) {
            this.showError = true;
            this.errorMessage = response.data.detail;
          } else if (response.data.date != undefined) {
            this.showError = true;
            this.errorMessage = response.data.date;
          } else if (response.data.details != undefined) {
            this.$toasted.global.info({
              message: response.data.details,
            });
          }
        }
        this.loading = false;
      });
    },
    close() {
      this.$emit("close");
      this.$refs.form.reset();
      this.showError= false;
      this.errorMessage = [];
      this.value = "";
      this.upd = {};
      this.provider = 0;
      this.loading = false;
      this.checkDate= undefined;
    },
  },
};
</script>
